.TicketPreviewDialog {
  .MuiDialog-paper {
    max-height: 97vh;
    max-width: 700px;
    margin: 0;
  }
}

.TicketPreview {
  display: flex;
  flex-direction: column;
  width: calc(99vh / 1.4142);
  min-width: 630px;
  max-width: 700px;
  height: 99vh;
  margin: 0;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px 20px 0 20px;
    margin: 0 0 20px 0;

    &__title {
      flex: 1;
      color: #263239;
      font-size: 24px;
      line-height: 27px;
      font-weight: 700;
      margin: 0;
    }

    .EventButton {
      background-color: #ff4842;
      width: 135px;
      min-width: 135px;
      height: 40px;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;

      .MuiButton-label {
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

    .__preview {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 30px 20px;
      height: 230px;

      &__inner {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .__group {
      display: flex;
      padding: 30px 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: normal;

      &.__top {
        .__side {
          flex: 1;

          &.__org {
            color: inherit;
            padding: 0 20px 0 0;

            .__title {
              color: inherit;
              font-size: 22px;
              line-height: 25px;
              font-weight: bold;
              margin: 0 0 6px 0;
            }
            .__name {
              font-size: 13px;
              line-height: 15px;
              font-weight: 300;
              margin: 0 0 15px 0;
            }
            .__date {
              font-size: 12px;
              line-height: 16px;
              margin: 0 0 15px 0;
            }
            .__other {
              color: inherit;
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
            }
          }

          &.__info {
            color: inherit;
            font-size: 13px;
            line-height: 21px;
          }
        }
      }

      &.__bottom {
        .__side {
          flex: 1;

          &.__user {
            flex: 1;
            display: flex;
            font-size: 13px;
            font-weight: 300;

            .__info {
              flex: 1;
              display: flex;
              flex-direction: column;

              .__info__num {
                .__label {
                  font-weight: 500;
                }
                .__value {
                  font-size: 12px;
                }
              }

              .__info__name {
                color: inherit;
                font-size: 22px;
                font-weight: bold;
                margin: 7px 0 10px 0;
                margin: 5px 0 7px 0;
              }

              .__info__pos {
                margin: 0 0 2px 0;
              }
              .__info__org {
                margin: 0;
              }
              .__info__other {
                margin: 30px 0 0 0;
              }
              .__info__email {
                margin: 5px 0 0 0;
              }
            }
          }

          &.__qr {
            display: flex;
            justify-content: center;
            align-items: center;

            .__qr {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 180px;
              height: 180px;

              border: 1px solid #dbdcdd;
              box-sizing: border-box;
              border-radius: 30px;

              img {
                width: 140px;
                width: 110px;
              }
            }
          }
        }
      }
    }
  }
}
