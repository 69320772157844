.TicketStep {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin: 0 0 100px 0;

  &__title {
    color: #637381;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin: 0 0 60px 0;

    @media (max-width: 850px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 40px 0;
    }
  }

  &__groups {
    display: flex;

    .__sep {
      width: 1px;
      background: #d9d9d9;
      margin: 0 60px;
    }
    .__group {
      flex: 1;

      &__title {
        color: #333333;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin: 0 0 46px 0;
      }

      .MuiGrid-container {
        width: calc(100% + 32px);
        margin-top: -32px;
        margin-left: -32px;
        margin-bottom: 0px;
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;

      .__sep {
        display: none;
      }

      .__group {
        margin: 0 0 40px 0;
      }
    }
  }

  &.__design &__title {
    margin: 0 0 60px 0;
  }
  &.__design &__groups {
    flex-direction: column;

    .__group {
      margin: 0 0 60px 0;

      &.__typo {
        .__title {
          color: #637381;
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          margin: 0 0 30px 0;
        }

        .__subgroups {
          display: flex;

          .__qr-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #919eab;
            padding: 10px;
            border-radius: 50px;
            width: 250px;
            height: 250px;
          }

          &__group {
            flex: 1;

            &.__left {
              padding: 0 20px 0 0;
            }
            &.__right {
              padding: 0 0 0 20px;

              &.__qr {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          @media (max-width: 850px) {
            flex-direction: column;

            &__group {
              &.__left {
                padding: 0;
                margin: 0 0 35px 0;
              }
              &.__right {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  &.__preview {
    display: flex;
    flex-direction: column;

    text-align: center;
    max-width: 480px;
    margin: auto;

    color: #637381;
    font-family: "Poppins", sans-serif;
    font-style: normal;

    .__illustration {
      max-height: 218px;
      margin: 0 0 34px 0;
    }

    .__desc,
    .__title {
      font-size: 40px;
      font-weight: 500;
      line-height: 30px;
      margin: 0 0 20px 0;

      span {
        color: #00ab55;
        cursor: pointer;
      }
    }

    .__desc {
      font-size: 20px;
      margin: 0;
    }

    @media (max-width: 850px) {
      .__illustration {
        max-height: 200px;
        margin: 0 0 20px 0;
      }

      .__title {
        font-size: 30px;
        margin: 0 0 10px 0;
      }
      .__desc {
        font-size: 16px;

        span {
          display: block;
        }
      }
    }
  }
}
