.ModalValidation {
  position: fixed;
  z-index: 9999;
  // ...
  display: flex;
  justify-content: center;
  align-items: center;
  // ...
  &__modal {
    font-family: "Poppins", sans-serif;
    position: relative;
    background-color: #fff;
    z-index: 9;
    width: 450px;
    border-radius: 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 15px 20px;

    // border: 1px solid red;

    .__title {
      color: #363636;
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
    }
  }

  &__body {
    color: #363636;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 10px 20px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;

    .__yes-btn {
      background-color: #263239;
      background-color: #FF4842;
      box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.15);
      min-width: 100px;

      .MuiButton-label {
        color: #fff;
      }
    }

    .__no-btn {
      background-color: transparent;
      box-shadow: unset;
      min-width: 100px;
      margin: 0 5px 0 0;

      .MuiButton-label {
        color: #263239;
      }
    }
  }
}
