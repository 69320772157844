// MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24  MuiDialog-paperScrollPaper MuiDialog-paperWidthFalse css-v6rd5l-MuiPaper-root-MuiDialog-paper
.BadgePreviewDialog {
  .MuiDialog-paper {
    max-height: 97vh;
    max-width: 700px;
    margin: 0;
  }
}

.BadgePreview {
  display: flex;
  flex-direction: column;
  width: calc(550px / 1.4142);
  min-width: 630px;
  max-width: 700px;
  height: 550px;
  margin: 0;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px 20px 0 20px;
    margin: 0 0 20px 0;

    &__title {
      flex: 1;
      color: #454545;
      font-size: 24px;
      line-height: 27px;
      font-weight: 700;
      margin: 0;
    }

    .EventButton {
      background-color: #ff4842;
      width: 135px;
      min-width: 135px;
      height: 40px;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;

      .MuiButton-label {
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;

    .__group {
      flex: 1;
      flex-grow: 1;
      display: flex;

      &.__top {
        .__side {
          margin-top: 0;
        }
      }

      &.__bottom {
        .__side {
          margin-bottom: 0;
        }
      }

      .__side {
        flex: 1;
        background: #f8f8fa;
        border-radius: 10px;
        padding: 20px 15px;
        margin: 10px;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;

        .__title {
          color: inherit;
          font-weight: bold;
          font-size: 16px;
          font-size: 18px;
          line-height: 20px;
          margin: 0 0 15px 0;
          margin: 0 0 25px 0;
        }

        &.__user {
          .__logo {
            width: 41px;
            height: 41px;
            background-color: #727272;
            border-radius: 1px;
            margin: 0 0 10px 0;
            margin: 0 0 18px 0;
          }

          .__details {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            max-width: calc(100% + 10px);
            margin: 0 -5px 20px -5px;

            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-size: 12px;
            font-weight: 300;

            .__details__body {
              flex: 1;
              display: flex;
              padding: 20px 15px 9px 15px;

              border: 1px solid #dfdfdf;
              border-bottom: none;
              border-radius: 20px 20px 0 0;

              .__info {
                flex: 1;
                display: flex;
                flex-direction: column;

                color: inherit;

                .__info__num {
                  .__label {
                    font-weight: 500;
                  }
                  .__value {
                    font-size: 12px;
                  }
                }

                .__info__name {
                  font-size: 25px;
                  font-size: 23px;
                  font-size: 17px;
                  font-weight: bold;
                  margin: 7px 0 10px 0;
                  margin: 5px 0 7px 0;
                }

                .__info__pos {
                  margin: 0 0 2px 0;
                }
                .__info__org {
                  margin: 0;
                }
              }

              .__qr {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 60px;
                }
              }
            }
            .__details__type {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              background-color: #ff8a00;
              border-radius: 0 0 20px 20px;
              border-radius: 0 0 17px 17px;

              color: #f8f8fa;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .__date {
            color: inherit;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: 1.2px;
            margin: 0 0 10px 0;
          }

          .__who {
            color: inherit;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1.2px;
          }
        }

        &.__partners {
          img {
            width: 100%;
          }
        }

        &.__note {
          p {
            color: inherit;
            font-family: "Poppins", sans-serif;
            text-align: justify;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
