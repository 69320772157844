.StageCard {
  font-family: "Poppins", sans-serif;

  &__label {
    color: #212b36;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 10px 10px 10px 10px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
