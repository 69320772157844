.AddEmail {
  &__header {
    font-family: "Poppins", sans-serif;
    font-style: normal;

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #637381;
      font-size: 14px;
      line-height: 28px;
      padding: 0 24px 25px 24px;

      .__left {
        font-weight: 500;
        opacity: 0;
      }
      .__right {
        min-width: 200px;

        .Entry {
          flex: 1;

          .MuiOutlinedInput-root {
            border-radius: 0;

            .MuiOutlinedInput-input {
              color: #637381;
              font-size: 16px;
              line-height: 24px;
              font-weight: bold;
              padding: 10px 45px;
            }

            .MuiOutlinedInput-notchedOutline {
              border: none;
            }
          }

          .MuiFormHelperText-root {
            display: none;
          }
        }
      }
    }

    &__subject {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-top: solid 1px rgba(145, 158, 171, 0.24);
      border-bottom: solid 1px rgba(145, 158, 171, 0.24);

      .Entry {
        flex: 1;

        .MuiOutlinedInput-root {
          border-radius: 0;

          .MuiOutlinedInput-input {
            color: #000;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            padding: 17px 24px;
          }

          .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }

        .MuiFormHelperText-root {
          display: none;
        }
      }
    }
  }

  &__wsysg {
    background-color: #fff;

    &__header {
      height: 40px;
      padding: 0 24px;
      border-bottom: solid 1px rgba(145, 158, 171, 0.24);
    }
    &__input {
      height: 278px;
      height: 320px;

      .Entry {
        flex: 1;
        height: 278px;
        height: 320px;

        .MuiOutlinedInput-root {
          border-radius: 0;
          height: 278px;
          height: 320px;

          .MuiOutlinedInput-input {
            height: 100% !important;
            color: #919eab;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 0;
            overflow: hidden !important;
            overflow-x: hidden !important;
            overflow-y: auto !important;

            // Scrollbar
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              // background: #f1f1f1;
              // background: #363636;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: #363636;
            }
            &::-webkit-scrollbar-thumb:hover {
              // background: #363636;
            }
          }

          .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }

        .MuiFormHelperText-root {
          display: none;
        }
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      min-height: 40px;
      padding: 12px 24px 10px 24px;
      border-top: solid 1px rgba(145, 158, 171, 0.24);
      border-bottom: solid 1px rgba(145, 158, 171, 0.24);
      background-color: #fff;

      .__title {
        color: #637381;
        font-family: "Be Vietnam", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
        margin: 0 0 15px 0;
      }

      .__tags {
        display: flex;
        flex-wrap: wrap;
      }

      .__tag {
        color: #212b36;
        font-family: "Be Vietnam", sans-serif;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        padding: 0 9px;
        height: 22px;
        background: #dfe3e8;
        border-radius: 6px;
        margin: 0 8px 8px 0;
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px;
      margin: 50px 0 0 0;
    }
  }

  &__files {
    // padding: 15px 24px 20px 24px;

    .__item {
      display: flex;
      background-color: #f4f6f8;
      padding: 8px 16px;
      border-bottom: solid 1px rgba(145, 158, 171, 0.24);

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 12px 0 0;
        background-color: #919eab;
        height: 40px;
        width: 40px;
        border-radius: 5px;
      }

      &__details {
        flex: 1;
        display: flex;
        flex-direction: column;

        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;

        &__name {
          color: #212b36;
          font-size: 14px;
          line-height: 22px;
        }

        &__date {
          color: #637381;
          font-size: 12px;
          line-height: 18px;
        }
      }

      &__delete {
        display: flex;
        align-items: center;

        svg {
          cursor: pointer;
        }
      }
    }

    &__add {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;

      label {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      input {
        position: absolute;
        z-index: -99;
      }

      span,
      img {
        cursor: pointer;
      }

      span {
        color: #363636;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
