.MuiFormControl-root {
  margin: 0;

  .MuiInputLabel-root {
    font-family: "Poppins";
    color: #454545;
    font-size: 16px;
    font-size: 14px;
    font-weight: 600;
  }
}

.Entry {
  &.__required {
    .MuiInputLabel-root {
      color: #454545;

      &::after {
        content: " *";
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 300;
        color: red;
      }
    }
  }
}

.EntryCheckbox {
  .MuiFormControlLabel-label {
    color: #637381;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }
}

.EntryColor {
  position: relative;

  &__preview,
  &__value,
  &__label {
    position: absolute;
    top: -25px;
    left: 0;

    font-family: "Poppins", sans-serif;
    color: #637381;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }

  &__preview {
    top: 10px;
    left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    box-shadow: rgba(145, 158, 171, 0.25) 0px 0px 5px;
  }

  &__value {
    top: 20px;
    left: 65px;

    color: #212b36;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }

  label {
    pointer-events: none;
    cursor: pointer;
  }

  input {
    opacity: 0;
    text-indent: -999em;
    cursor: pointer;
  }

  &__name,
  &__btn {
    position: absolute;
    pointer-events: none;
  }

  &__name {
    top: 18px;
    left: 0;
    margin: 0 0 0 14px;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;

    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.EntryFile {
  position: relative;

  &__label {
    position: absolute;
    top: -25px;
    left: 0;

    font-family: "Poppins", sans-serif;
    color: #333;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;

    &.__required {
      &::after {
        content: " *";
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: red;
      }
    }
  }

  .EntryText {
    .MuiInputLabel-root {
      font-weight: 400;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    text-indent: -999em;
    z-index: -999;
  }

  &__btn {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 999;

    &__inner {
      display: flex;
      color: #fff;
      background: #00ab55;
      width: 196px;
      height: 40px;
      margin: 0 14px 0 0;
      z-index: 99;

      &.__compact {
        width: 40px;
      }
      &.__medium {
        width: 80px !important;
      }

      &:hover {
        background: #00ab55;
      }
    }
  }

  &__preview {
    margin: 20px 0 0 0;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  @media (max-width: 850px) {
  }
}

.EntryAutocomplete {
  position: relative;

  .MuiAutocomplete-endAdornment {
    // border: 1px solid red;
    display: none;
  }

  &__helper {
    position: absolute;
    top: -30px;
    left: 4px;

    color: #333;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
  &__chips {
    margin: 10px -5px 0 -5px;

    .MuiChip-root {
      background: transparent;
      border: 1px solid #00ab55;
      margin: 0 5px 5px 5px;

      .MuiChip-label {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: #00ab55;
        min-width: 70px;
        padding-top: 5px;
      }

      svg {
        color: #00ab55;
      }
    }
  }
}

.chrome-picker {
  // width: 400px !important;
  // border: 10px solid red;
  & > div:nth-child(2) {
    & > div:nth-child(1) {
      & > div:nth-child(1) {
        display: none !important;
      }
      & > div:nth-child(2) {
        & > div:nth-child(1) {
          margin: 0 !important;
        }
        & > div:nth-child(2) {
          display: none !important;
        }
      }
    }
    & > div:nth-child(2) {
      display: none !important;
    }
  }
}

.EntryDateButton {
  position: relative;

  &__trigger {
    position: relative;
  }

  &__inp {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.EntryDateButtonPopover {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
}
