.EventHeader {
  font-family: "Montserrat", sans-serif;
  font-style: normal;

  &__inner {
    display: flex;
    background: linear-gradient(267.16deg, #09575b 0.6%, #22394d 49.45%, #004a25 98.71%);
    padding-bottom: 10px !important;
  }

  &__left {
    width: 70%;
  }

  &__right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(145, 158, 171, 0.32);
    padding: 0 0 0 24px;

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      // max-width: 240px;
    }

    .__status {
      display: flex;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(14, 140, 147, 0.08);
        border-radius: 8px;
        height: 43px;
        width: 43px;
        margin: 0 9px 0 0;
      }
      &__content {
        color: #e6e6e6;
        font-family: "Montserrat", sans-serif;
        font-style: normal;

        h3 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin: 0 0 3px 0;
        }

        p {
          color: #637381;
          font-size: 10px;
          line-height: 24px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
    .__btn {
      width: 100%;
      display: flex;
    }
  }

  &__content {
    display: flex;
    .__date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 66px;
      width: 66px;
      border: 1px solid #637381;
      border-radius: 12px;
      background: transparent;

      text-align: center;

      .__day {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #f8f8fa;
        margin: 0 0 3px 0;
      }
      .__month {
        color: #637381;
        font-size: 9px;
        line-height: 10px;
        font-weight: 500;
      }
    }

    .__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 66px;
      margin: 0 0 0 20px;
    }

    .__title {
      color: #f8f8fa;
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
    }

    .__desc {
      color: #637381;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__img {
    height: 162px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__children {
    margin: 10px 0 0 0;
  }

  @media (max-width: 850px) {
    border-radius: 6px !important;

    &__inner {
      flex-direction: column;
      padding: 10px;
    }

    &__left {
      width: 100%;
    }

    &__right {
      display: none;
    }

    &__content {
      .__date {
        height: 45px;
        width: 45px;
        border-radius: 6px;

        .__day {
          font-size: 18px;
          line-height: 22px;
        }
        .__month {
          font-size: 10px;
        }
      }

      .__inner {
        height: auto;
        margin: 0 0 0 15px;
      }

      .__title {
        color: #f8f8fa;
        font-size: 25px;
        line-height: 30px;
        font-weight: 600;
        margin: 0 0 3px 0;
      }

      .__desc {
        font-size: 12px;
        line-height: 15px;
      }
    }

    &__children {
      margin: 0;
    }
  }
}
