.EventStageItem {
  &__group {
    &.__title {
      color: rgba(99, 115, 129, 0.78);
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;

      margin: 0 0 68px 0;
    }

    &.__dates {
      display: flex;
      background: transparent;
      padding: 10px 17px;
      margin: 0 0 34px 0;

      .MuiTabs-root {
        flex: 1;
        display: block;
      }

      .MuiTab-wrapper {
        justify-content: center;
      }

      .MuiTabs-flexContainer {
      }

      .__empty {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        color: #363636;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 1px;
      }
    }
  }
}

.DateTab {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:hover &__dots {
    opacity: 1;
  }

  &__label {
    color: #637381;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  &__dots {
    position: absolute;
    top: 0;
    right: -20px;
    opacity: 0;
  }
}
