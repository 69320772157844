.add-speaker {
  &__group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    &:first-child {
      margin-bottom: 50px;
    }
    &:nth-child(2) {
      margin-bottom: 40px;
    }
  }
  &__item {
    width: 47.5%;
  }
  &__upload-img {
    aspect-ratio: 1;
    height: 100%;
    width: 210px;
    border-radius: 50%;
    border: 1px dashed rgba(#637381, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    &__add {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__inner {
      height: 95%;
      width: 199.5px;
      border-radius: 50%;
      background: rgba(#637381, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__check {
    display: flex;
    align-items: flex-end;
    height: 100%;
    &__inner {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
  }
  &__entry {
    label.MuiFormLabel-root.MuiInputLabel-root {
      margin: 0 0 1px 0;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  &__action {
    display: flex;
    justify-content: center;
    margin-top: 120px;
    &-box {
    }
  }
  &__btn {
    width: 245px;
    &--cancel {
      background: transparent;
      border: 1px solid #777777;
      color: #777777;
      margin-right: 50px;
    }
    &--add {
      background: #0e8c93;
    }
  }
}
