.EventActions {
  display: flex;
  position: fixed;
  right: 7px;
  bottom: 0;
  height: 75px;
  width: calc(100% - 287px);
  background-color: #fff;
  padding: 0 100px;
  box-shadow: 0px -1px 1px rgba(82, 82, 82, 0.1);
  z-index: 9;

  &__inner {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;

    .__cancel {
      margin: 0 20px 0 0;
    }
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
}
