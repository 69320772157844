.EntitiesAutocomplete {
  position: relative;

  &__helper {
    position: absolute;
    top: -30px;
    left: 4px;

    color: #333;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;

    &.__new {
      left: unset;
      top: 17px;
      right: 65px;
      color: #00ab55;
      font-weight: 600;
      cursor: pointer;
      z-index: 99;
    }
  }

  &__chips {
    margin: 10px -5px 0 -5px;

    .MuiChip-root {
      background: transparent;
      border: 1px solid #00ab55;
      margin: 0 5px 5px 5px;

      .MuiChip-label {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: #00ab55;
        min-width: 70px;
        padding-top: 5px;
      }

      svg {
        color: #00ab55;
      }
    }
  }
}
