.EventAuth {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #010101;
  overflow: hidden;

  &__inner {
    flex: 1;
    position: relative;
    display: flex;
    padding: 0 80px;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;

    &__inner {
      display: flex;
      flex-direction: column;
    }

    .__top {
      display: flex;
      align-items: center;
      color: #707070;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      margin: 0 0 25px 0;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        background-color: rgba(15, 198, 92, 0.24);
        margin: 0 20px 0 0;

        .__inner {
          width: 23px;
          height: 23px;
          border-radius: 50%;
          background-color: #0fc65c;
        }
      }
    }

    .__title {
      color: #ffffff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 75px;
      line-height: 90px;
      margin: 0 0 30px 0;
    }

    .__list {
      margin: 0 0 40px 0;

      &__item {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 34px;
        margin: 0 0 21px 0;

        .__num {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background-color: #2d2d2d;
          margin: 0 11px 0 0;

          color: #ffffff;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .__new {
      margin: 0 0 40px 0;

      &__title {
        color: #f0f0f0;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 45px;
        margin: 0 0 20px 0;
      }

      .MuiInputBase-input {
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
      }
    }

    .__actions {
      button {
        margin: 0 24px 0 0;
      }
    }
  }

  &__preview {
    flex: 1;
    position: relative;

    svg {
      width: 100%;
      // height: 100%;
    }
  }

  @media (max-width: 1440px) {
    &__inner {
      padding: 0 40px;
    }

    &__content {
      flex: 3;
      .__title {
        font-size: 65px;
        line-height: 90px;
        margin: 0 0 28px 0;
      }
    }

    &__preview {
      flex: 2;
    }
  }

  @media (max-width: 1140px) {
    &__inner {
      padding: 0 30px;
    }

    &__preview {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      svg {
        width: unset;
        height: 100%;
      }
    }
  }

  @media (max-width: 920px) {
    &__inner {
      padding: 0 30px;
    }

    &__preview {
      display: none;
    }
  }
}
