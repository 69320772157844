.Breadcrumbs {
  display: flex;
  margin: 0 0 0 5px;

  &__link {
    position: relative;
    color: #b4bcc3;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    text-transform: capitalize;
    height: 48px;
    padding: 20px 0 0 0;
    margin: 0 30px 0 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 15px;
      right: -16px;
      width: 6px;
      height: 6px;
      background: #919eab;
      border-radius: 50%;
    }

    &:last-child {
      color: #fff;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 850px) {
    &__link {
      font-size: 12px;
      margin: 0 15px 0 0;

      &::after {
        right: -10px;
      }
    }
  }
}
