.ball-loader {
  width: 50px;
  height: (50px / 3) - 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .ball-loader-ball {
    height: (50px / 3) - 10px;
    width: (50px / 3) - 10px;
  }

  &.small {
    width: 40px;

    .ball-loader-ball {
      height: 7px;
      width: 7px;
    }
  }
}

.ball-loader-ball {
  will-change: transform;
  border-radius: 50%;
  background-color: #00ab55;
  position: absolute;
  animation: grow 0.8s ease-in-out infinite alternate;

  &.white {
    background-color: #fff;
  }
  &.orange {
    background-color: orange;
  }

  &.ball1 {
    left: 0;
    transform-origin: 100% 50%;
  }
  &.ball2 {
    left: 50%;
    transform: translateX(-50%) scale(1);
    animation-delay: 0.33s;
  }
  &.ball3 {
    right: 0;
    animation-delay: 0.66s;
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}
