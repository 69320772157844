.AddAttendee {
  .EntryFile .MuiInputBase-input {
    padding: 18px 14px;
  }

  .EventButton {
    min-width: 100px;
    height: 45px;
  }

  &__sep {
    width: 100;
    height: 1px;
    background: #d9d9d9;
    margin: 60px 0;
  }

  &__title {
    color: #333333;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 30px 0;

    &.__ {
      margin: 0 0 20px 0;
    }

    &.__sub {
      color: #637381;
      margin: 0 0 25px 0;

      &.__ {
        margin: 0 0 50px 0;
      }
    }

    @media (max-width: 850px) {
      font-size: 13px;
      margin: 0 0 15px 0;
    }
  }
}
