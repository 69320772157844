.EventButton {
  background-color: #263239;
  box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.15);

  &.back {
    background-color: #fff;
    border: 1px solid rgba(145, 158, 171, 0.32);
    box-shadow: none;

    &:hover {
      background-color: #fff;
      box-shadow: none;
    }

    .MuiButton-label {
      color: #263239;
    }
  }

  &:hover {
    background-color: #263239;
    box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.1);
  }

  &.__emails {
    width: auto;
    background: transparent;
    box-shadow: none;
    padding: 4px 0;

    &:hover {
      background: transparent;
      box-shadow: none;
    }

    .MuiButton-label {
      font-family: "Poppins", sans-serif;
      color: #09575B;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  @media (max-width: 850px) {
    min-width: 100px;
    padding: 5px 20px;

    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
