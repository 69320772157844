.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: grey;
  cursor: pointer;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.MuiModal-root,
.MuiDrawer-root {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.MuiMenu-root {
  .MuiBackdrop-root {
    background: rgba(99, 37, 37, 0) !important;
  }
}

.SidebarContent {
  display: flex;
  flex-direction: column;

  .__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 49px;
    width: 51px;
    min-width: 51px;
    max-width: 51px;
    border: 0.4px solid #637381;
    border-radius: 12px;
    background: transparent;

    text-align: center;

    .__day {
      color: #dfe3e8;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      margin: 0 0 3px 0;
    }
    .__month {
      color: #b4bcc3;
      font-size: 9px;
      line-height: 10px;
      font-weight: 500;
    }
  }

  .__details {
    flex: 1;
    color: #b4bcc3;
    font-family: "Poppins";
    font-size: 14px;
    overflow: hidden;

    &__title {
      font-size: 15px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &__preview {
      color: #00ab55;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.SnackbarItem-contentRoot {
  // border: 10px solid red;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0, 0, 0, 0.05),
    0px 1px 18px 0px rgba(0, 0, 0, 0.05) !important;
}
