.speaker__photo {
  border-radius: 50%;
  height: 160px;
  width: 160px;
  padding: 7px;
  margin: 0 20px;
  border: 1px dashed rgba(145, 158, 171, 0.32);
  overflow: hidden;
  cursor: pointer;

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f4f6f8;
    background-clip: content-box;
    border-radius: 50%;

    input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    .__icon {
      margin: 0 0 10px 0;
    }

    .__label {
      color: #637381;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    height: 140px;
    width: 140px;
    padding: 7px;
    margin: 0 auto 20px auto;
  }
}
