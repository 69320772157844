.EmptyView {
  &__svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: 0 auto 28px auto;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 287px);

    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &__content {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    text-align: center;

    &__title {
      color: #454545;
      font-size: 28px;
      line-height: 54px;
      font-weight: 700;
    }

    &__sub {
      color: #637381;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1.2px;
      max-width: 320px;
      margin: 0 auto 26px auto;
    }

    .__button {
    }
  }
}
