.AddSponsor {
  .EntryFile .MuiInputBase-input {
    padding: 18px 14px;
  }

  .sponsor__banner {
    border-radius: 18px;
    background: rgba(9, 137, 150, 0.1);
    height: 140px;
    margin: 0 0 40px 0;
    overflow: hidden;
    cursor: pointer;

    &.isReadOnly {
      cursor: default;
    }

    &__img {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .__icon {
        margin: 0 0 10px 0;
      }

      .__label {
        color: #637381;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }
    }

    @media (max-width: 850px) {
      height: 100px;
      margin: 0 0 70px 0;

      &__inner {
        margin: 20px 0 0 0;
        justify-content: flex-start;

        .__icon {
          margin: 0 0 5px 0;
        }

        .__label {
          font-size: 9px;
        }
      }
    }
  }

  .sponsor__photo {
    position: absolute;
    top: 80px;
    left: 0;
    border-radius: 50%;
    height: 140px;
    width: 140px;
    padding: 7px;
    margin: 0 20px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
    overflow: hidden;
    cursor: pointer;
    z-index: 99;

    &__img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: #f4f6f8;
      background-clip: content-box;
      border-radius: 50%;

      input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .__icon {
        margin: 0 0 10px 0;
      }

      .__label {
        color: #637381;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
      }
    }

    @media (max-width: 850px) {
      top: 70px;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100px;
      width: 100px;
      padding: 5px;
      margin: 0;

      &__inner {
        .__label {
          font-size: 9px;
        }
      }
    }
  }
}
