.MuiStepper-root.EventStepper {
  width: 100%;
  margin: 0 0 70px 0;

  @media (max-width: 850px) {
    margin: 0 0 50px 0 !important;
  }

  .MuiStep-root.__step {
    .__label {
      cursor: pointer;
    }

    .MuiStepConnector-root {
      .MuiStepConnector-line {
        height: 1px;
        background-color: #f9f9f9;
      }
    }

    .MuiSvgIcon-root {
      color: rgba(145, 158, 171, 0.24);
      width: 28px;
      height: 28px;

      .MuiStepIcon-text {
        fill: #fff;
      }

      &.Mui-active {
        color: #00ab55;
      }

      &.MuiStepIcon-completed {
        color: #00ab55;
      }
    }
  }

  .MuiStepLabel-label {
    color: #919eab;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
    // width: 100px;
    margin: 15px auto 0 auto;
    cursor: pointer;

    &.Mui-active,
    &.MuiStepLabel-completed {
      color: #263239;
    }

    @media (max-width: 850px) {
      width: 80px;
    }
  }
}
