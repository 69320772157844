.MuiStepper-root.EventSetupStepper {
  width: 100%;
  margin: 0 0 70px 0;

  @media (max-width: 850px) {
    margin: 0 0 50px 0 !important;
  }

  .MuiStep-root.__step {
    .__label {
      cursor: pointer;
    }

    .MuiStepLabel-root {
      .MuiStepLabel-iconContainer {
        display: flex;
        justify-content: center;
        height: 27px;
        width: 38px;
        overflow: hidden;

        .MuiSvgIcon-root {
          width: 28px;
          height: 28px;
        }
      }
    }

    .MuiStepLabel-label {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      text-align: center;
      margin: 5px auto 0 auto;
      cursor: pointer;

      &.Mui-error,
      &.MuiStepLabel-completed {
        color: #919eab;
      }

      &.Mui-active {
        color: #000;
      }

      @media (max-width: 850px) {
        width: 80px;
      }
    }

    .MuiStepConnector-root {
      .MuiStepConnector-line {
        height: 1px;
        background-color: #f9f9f9;
      }
    }
  }
}
